import Vue from 'vue'

import {
  getMatchedComponentsInstances,
  getChildrenComponentInstancesUsingFetch,
  promisify,
  globalHandleError,
  sanitizeComponent
} from './utils'

import NuxtError from '../layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css'

import _68c71ccc from '../layouts/base/AppBar.vue'
import _9727172c from '../layouts/base/Index.vue'
import _2f221c0d from '../layouts/base/View.vue'
import _7efd4f21 from '../layouts/common-drawer/Customizer.vue'
import _11cb1b64 from '../layouts/common-drawer/NotificationDrawer.vue'
import _655f62cb from '../layouts/common-drawer/SearchDrawer.vue'
import _2263df2e from '../layouts/common-drawer/UserDrawer.vue'
import _6f6c098b from '../layouts/default.vue'
import _727d7967 from '../layouts/horizontal/AppBar.vue'
import _7af7e4d0 from '../layouts/horizontal/Footer.vue'
import _5ad68206 from '../layouts/horizontal/Index.vue'
import _ee527a72 from '../layouts/horizontal/Sidebar.vue'
import _05433e70 from '../layouts/horizontal/TopNavbar.vue'
import _b9c9da7c from '../layouts/horizontal/TopNavMenu.vue'
import _9f03cd5e from '../layouts/horizontal/UserDrawer.vue'
import _136699cc from '../layouts/horizontal/View.vue'
import _3ecd43e8 from '../layouts/vertical-compact-layout/AppBar.vue'
import _2dd86d16 from '../layouts/vertical-compact-layout/Footer.vue'
import _6ac731b8 from '../layouts/vertical-compact-layout/Index.vue'
import _43911a7c from '../layouts/vertical-compact-layout/Sidebar.vue'
import _1a190602 from '../layouts/vertical-compact-layout/View.vue'
import _bfb82a6a from '../layouts/vertical-saas-layout/AppBar.vue'
import _aec35398 from '../layouts/vertical-saas-layout/Footer.vue'
import _3f689119 from '../layouts/vertical-saas-layout/Index.vue'
import _0ffe7de3 from '../layouts/vertical-saas-layout/Sidebar.vue'
import _5ce82d7e from '../layouts/vertical-saas-layout/View.vue'
import _ab306f56 from '../layouts/vertical/AppBar.vue'
import _9a3b9884 from '../layouts/vertical/Footer.vue'
import _fc644b62 from '../layouts/vertical/Index.vue'
import _4e365199 from '../layouts/vertical/Sidebar.vue'
import _4550f02c from '../layouts/vertical/SubHeader.vue'
import _14b9e588 from '../layouts/vertical/View.vue'

const layouts = { "_base/AppBar": sanitizeComponent(_68c71ccc),"_base/Index": sanitizeComponent(_9727172c),"_base/View": sanitizeComponent(_2f221c0d),"_common-drawer/Customizer": sanitizeComponent(_7efd4f21),"_common-drawer/NotificationDrawer": sanitizeComponent(_11cb1b64),"_common-drawer/SearchDrawer": sanitizeComponent(_655f62cb),"_common-drawer/UserDrawer": sanitizeComponent(_2263df2e),"_default": sanitizeComponent(_6f6c098b),"_horizontal/AppBar": sanitizeComponent(_727d7967),"_horizontal/Footer": sanitizeComponent(_7af7e4d0),"_horizontal/Index": sanitizeComponent(_5ad68206),"_horizontal/Sidebar": sanitizeComponent(_ee527a72),"_horizontal/TopNavbar": sanitizeComponent(_05433e70),"_horizontal/TopNavMenu": sanitizeComponent(_b9c9da7c),"_horizontal/UserDrawer": sanitizeComponent(_9f03cd5e),"_horizontal/View": sanitizeComponent(_136699cc),"_vertical-compact-layout/AppBar": sanitizeComponent(_3ecd43e8),"_vertical-compact-layout/Footer": sanitizeComponent(_2dd86d16),"_vertical-compact-layout/Index": sanitizeComponent(_6ac731b8),"_vertical-compact-layout/Sidebar": sanitizeComponent(_43911a7c),"_vertical-compact-layout/View": sanitizeComponent(_1a190602),"_vertical-saas-layout/AppBar": sanitizeComponent(_bfb82a6a),"_vertical-saas-layout/Footer": sanitizeComponent(_aec35398),"_vertical-saas-layout/Index": sanitizeComponent(_3f689119),"_vertical-saas-layout/Sidebar": sanitizeComponent(_0ffe7de3),"_vertical-saas-layout/View": sanitizeComponent(_5ce82d7e),"_vertical/AppBar": sanitizeComponent(_ab306f56),"_vertical/Footer": sanitizeComponent(_9a3b9884),"_vertical/Index": sanitizeComponent(_fc644b62),"_vertical/Sidebar": sanitizeComponent(_4e365199),"_vertical/SubHeader": sanitizeComponent(_4550f02c),"_vertical/View": sanitizeComponent(_14b9e588) }

export default {
  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    if (this.nuxt.err && NuxtError) {
      const errorLayout = (NuxtError.options || NuxtError).layout
      if (errorLayout) {
        this.setLayout(
          typeof errorLayout === 'function'
            ? errorLayout.call(NuxtError, this.context)
            : errorLayout
        )
      }
    }

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [templateEl])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: '',

    nbFetching: 0
    }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  mounted () {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    },

      isFetching() {
      return this.nbFetching > 0
    }
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map((page) => {
        const p = []

        // Old fetch
        if (page.$options.fetch && page.$options.fetch.length) {
          p.push(promisify(page.$options.fetch, this.context))
        }
        if (page.$fetch) {
          p.push(page.$fetch())
        } else {
          // Get all component instance to call $fetch
          for (const component of getChildrenComponentInstancesUsingFetch(page.$vnode.componentInstance)) {
            p.push(component.$fetch())
          }
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail(error)
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },

    errorChanged () {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) {
          this.$loading.fail(this.nuxt.err)
        }
        if (this.$loading.finish) {
          this.$loading.finish()
        }
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },

  components: {
    NuxtLoading
  }
}
