import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b9208678 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _89773d62 = () => interopDefault(import('../pages/FigureQuestionView.vue' /* webpackChunkName: "pages/FigureQuestionView" */))
const _174aec56 = () => interopDefault(import('../pages/landingpage.vue' /* webpackChunkName: "pages/landingpage" */))
const _1a616bc4 = () => interopDefault(import('../pages/learning.vue' /* webpackChunkName: "pages/learning" */))
const _c4239cbe = () => interopDefault(import('../pages/LearningManagement.vue' /* webpackChunkName: "pages/LearningManagement" */))
const _115249ce = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d51baed4 = () => interopDefault(import('../pages/read.vue' /* webpackChunkName: "pages/read" */))
const _6856f38e = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _ffd58550 = () => interopDefault(import('../pages/SignUp.vue' /* webpackChunkName: "pages/SignUp" */))
const _77ce9316 = () => interopDefault(import('../pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _2daf3802 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _b9208678,
    name: "dashboard"
  }, {
    path: "/FigureQuestionView",
    component: _89773d62,
    name: "FigureQuestionView"
  }, {
    path: "/landingpage",
    component: _174aec56,
    name: "landingpage"
  }, {
    path: "/learning",
    component: _1a616bc4,
    name: "learning"
  }, {
    path: "/LearningManagement",
    component: _c4239cbe,
    name: "LearningManagement"
  }, {
    path: "/login",
    component: _115249ce,
    name: "login"
  }, {
    path: "/read",
    component: _d51baed4,
    name: "read"
  }, {
    path: "/registration",
    component: _6856f38e,
    name: "registration"
  }, {
    path: "/SignUp",
    component: _ffd58550,
    name: "SignUp"
  }, {
    path: "/event/:id?",
    component: _77ce9316,
    name: "event-id"
  }, {
    path: "/",
    component: _2daf3802,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
