var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',_vm._b({staticClass:"shadow-sm",attrs:{"color":_vm.$vuetify.theme.dark
            ? 'dark'
            : _vm.getThemeMode.verticalSidebarDrawerColor,"expand-on-hover":_vm.getThemeMode.verticalSidebarMini,"mini-variant":_vm.getThemeMode.verticalSidebarMini,"src":_vm.bg,"dark":_vm.getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false,"app":"","disable-resize-watcher":false,"mobile-breakpoint":960,"height":"100%","floating":true,"right":_vm.$vuetify.rtl},on:{"update:expandOnHover":function($event){return _vm.$set(_vm.getThemeMode, "verticalSidebarMini", $event)},"update:expand-on-hover":function($event){return _vm.$set(_vm.getThemeMode, "verticalSidebarMini", $event)}},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({staticClass:"test",style:({opacity: _vm.opacity})},'v-img',props,false))]}}]),model:{value:(_vm.getThemeMode.verticalSidebarDrawer),callback:function ($$v) {_vm.$set(_vm.getThemeMode, "verticalSidebarDrawer", $$v)},expression:"getThemeMode.verticalSidebarDrawer"}},'v-navigation-drawer',_vm.$attrs,false),[_vm._v(" "),_c('vue-perfect-scrollbar',{staticClass:"h-100 rtl-ps-none ps scroll",staticStyle:{"height":"100%"},attrs:{"settings":{suppressScrollX: true, wheelPropagation: false}}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-avatar',[(
                            _vm.getThemeMode.verticalSidebarDrawerColor ==
                                'dark'
                        )?_c('v-img',{attrs:{"src":"assets/images/arctic-circle-logo.svg"}}):_c('v-img',{attrs:{"src":"assets/images/logo.svg"}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"text-18 text-uppercase text-default"},[_vm._v("Octavia")]),_vm._v(" "),_c('v-scroll-x-transition',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[(!_vm.getThemeMode.verticalSidebarMini)?_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-circle-slice-8")])]}}],null,false,1300895161)},[_vm._v(" "),_c('span',[_vm._v("UnPin")])]):_vm._e(),_vm._v(" "),(_vm.getThemeMode.verticalSidebarMini)?_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-circle-outline")])]}}],null,false,3279625276)},[_vm._v(" "),_c('span',[_vm._v("pin")])]):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-list-item',{staticClass:"pa-1",class:_vm.miniVariant && 'px-1',attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"ml-n1",attrs:{"min-width":"40","height":"40","width":"40"}},[_c('v-img',{attrs:{"src":"assets/images/faces/1.jpg"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-16 mb-1 font-weight-regular"},[_vm._v("Watson Joyce")]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"mt-n1"},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cog")])],1),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-logout-variant")])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-list',[_vm._l((_vm.computedItems),function(item,i){return [(item.children)?_c('base-item-group',{key:("group-" + i),attrs:{"item":item}}):_c('base-item',{key:("item-" + i),attrs:{"item":item}})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }