export const state = () => ({
    hasAcceptedCookies : false,
    alreadyInitialized : false,
})

export const mutations = {
    SET_ALREADY_INITIALIZED(state, val){ 
        state.alreadyInitialized=val; 
    },
    SET_HAS_ACCEPTED_COOKIES(state, val){ 
        state.hasAcceptedCookies=val; 
    },
}