export default (context, inject) => {

function calcXsElementHeight(breakpoint, mult=1){
    let height = 10;
    switch (breakpoint.name) {
      case 'xs':
        height = 75;
      case 'sm':
        height = 50;
      case 'md':
        height = 35;
      case 'lg':
        height = 25;
      case 'xl':
        height = 10;
    }
    return String(parseInt(mult*height))+"vh"
}

function calcSmElementHeight(breakpoint, mult=1){
  let height = 10;
        switch (breakpoint.name) {
          case 'xs':
            height = 100;
          case 'sm':
            height = 75;
          case 'md':
            height = 50;
          case 'lg':
            height = 25;
          case 'xl':
            height = 15;
        }
        return String(parseInt(mult*height))+"vh"
}

function calcMdElementHeight(breakpoint, mult=1){
  let height = 10;
    switch (breakpoint.name) {
      case 'xs':
        height = 150;
      case 'sm':
        height = 100;
      case 'md':
        height = 75;
      case 'lg':
        height = 50;
      case 'xl':
        height = 35;
    }
    return String(parseInt(mult*height))+"vh"
}

function calcLgElementHeight(breakpoint, mult=1){
  let height = 10;
    switch (breakpoint.name) {
      case 'xs':
        height = 200;
      case 'sm':
        height = 150;
      case 'md':
        height = 100;
      case 'lg':
        height = 75;
      case 'xl':
        height = 50;
    }
    return String(parseInt(mult*height))+"vh"
}


function calcXsElementWidth(breakpoint, mult=1){
  let height = 10;
    switch (breakpoint.name) {
      case 'xs':
        height = 75;
      case 'sm':
        height = 50;
      case 'md':
        height = 35;
      case 'lg':
        height = 20;
      case 'xl':
        height = 10;
    }
    return String(parseInt(mult*height))+"vh"
}

function calcSmElementWidth(breakpoint, mult=1){
  let height = 10;
        switch (breakpoint.name) {
          case 'xs':
            height = 100;
          case 'sm':
            height = 75;
          case 'md':
            height = 50;
          case 'lg':
            height = 25;
          case 'xl':
            height = 15;
        }
        return String(parseInt(mult*height))+"vw"
}

function calcMdElementWidth(breakpoint, mult=1){
  let height = 10;
    switch (breakpoint.name) {
      case 'xs':
        height = 100;
      case 'sm':
        height = 75;
      case 'md':
        height = 50;
      case 'lg':
        height = 35;
      case 'xl':
        height = 25;
    }
    return String(parseInt(mult*height))+"vw"
}

function calcLgElementWidth(breakpoint, mult=1){
  let height = 10;
    switch (breakpoint.name) {
      case 'xs':
        height = 200;
      case 'sm':
        height = 150;
      case 'md':
        height = 100;
      case 'lg':
        height = 75;
      case 'xl':
        height = 50;
    }
    return String(parseInt(mult*height))+"vw"
}

inject('calcLgElementWidth', calcLgElementWidth)
context.$calcLgElementWidth = calcLgElementWidth
inject('calcMdElementWidth', calcMdElementWidth)
context.$calcMdElementWidth = calcMdElementWidth
inject('calcSmElementWidth', calcSmElementWidth)
context.$calcSmElementWidth = calcSmElementWidth
inject('calcXsElementWidth', calcXsElementWidth)
context.$calcXsElementWidth = calcXsElementWidth

inject('calcLgElementHeight', calcLgElementHeight)
context.$calcLgElementHeight = calcLgElementHeight
inject('calcMdElementHeight', calcMdElementHeight)
context.$calcMdElementHeight = calcMdElementHeight
inject('calcSmElementHeight', calcSmElementHeight)
context.$calcSmElementHeight = calcSmElementHeight
inject('calcXsElementHeight', calcXsElementHeight)
context.$calcXsElementHeight = calcXsElementHeight

}