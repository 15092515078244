<template>
  <v-app dark>
    <client-only>
      <TheSnackbar />
      <v-main>
        <nuxt />
      </v-main>
    </client-only>
  </v-app>
</template>

<script>
import TheSnackbar from "@/components/TheSnackbar.vue";
export default {
  data() {
    return {
      logOutKey: 0,
      appBarItems: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" },
      ],
      right: null,
      clipped: false,
      drawer: false,
      fixed: false,
      navBar: null,
      items: [
        {
          icon: "mdi-view-dashboard-variant-outline",
          title: "Dashboard",
          to: "/learningmanagement",
        },
        {
          icon: "mdi-database",
          title: "Ressources",
          to: "/",
        },
        {
          icon: "mdi-school-outline",
          title: "Learning",
          to: "/learning",
        },
      ],
      miniVariant: false,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },
  mounted() {
    // this.$axios.$get('user/loggedIn/').then((data)=> {
    //   this.$store.commit('SET_LOGIN_STATE', data)
    // }
    // );
    // this.$axios.setHeader("Content-Type", "application/json");
    // this.$axios.setHeader("X-CSRFTOKEN", this.getCookie("csrftoken"));
    this.$axios.defaults.xsrfCookieName = "csrftoken";
    this.$axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    let test = 1;
    
    if (this.$auth.loggedIn){
      this.$store.dispatch('data/loadAll');
    }

    setInterval(
      () => (this.logOutKey === 1 ? this.logOutKey-- : this.logOutKey++),
      1000
    );
    // console.log("CSRF :" + this.getCookie('csrftoken'));
  },
  components: {
    TheSnackbar,
  },
  methods: {
    async logOut() {
      await this.$auth.logout();
      this.$router.push("/login");
      this.$store.dispatch("snackbar/setSnackbar", {
        color: "blue",
        text: "Goodbye!",
      });
    },
    getCookie(name) {
      var cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
  },
};
</script>

<style scoped>
li a {
  text-decoration: none;
}

nuxt-link-exact-active {
  text-decoration: none;
}

nuxt-link-active {
  text-decoration: none;
}
</style>
