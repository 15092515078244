<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
      :key="snackbar.text + Math.random()"
      v-model="snackbar.showing"
      :color="snackbar.color"
      :style="`bottom: ${(index * 60) + 8}px`"
    >
    <v-container fluid justify-center text-center>
      {{snackbar.text}}

      <v-btn class="close-btn" text @click="closeSnackbar(index)">
        Close
      </v-btn>
    </v-container>
    </v-snackbar>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    methods: {
    closeSnackbar(index){
        this.$store.commit('snackbar/disableSnackbar', index)
    }
    },
    computed: {
      ...mapState({
        snackbars: state => state.snackbar.snackbars
      })
    },    
  }
</script>

<style lang="scss" scoped>
.close-btn {
    text-align: end;
}
</style>