let fkRels={
    "ressource": [
     {
      "modelName": "page",
      "fieldName": "ressource"
     },
     {
      "modelName": "deck",
      "fieldName": "ressource"
     }
    ],
    "label": [
     {
      "modelName": "boundingBox",
      "fieldName": "label"
     }
    ],
    "boundingBox": [
     {
      "modelName": "abstract",
      "fieldName": "bbox"
     },
     {
      "modelName": "author",
      "fieldName": "bbox"
     },
     {
      "modelName": "caption",
      "fieldName": "bbox"
     },
     {
      "modelName": "date",
      "fieldName": "bbox"
     },
     {
      "modelName": "equation",
      "fieldName": "bbox"
     },
     {
      "modelName": "figure",
      "fieldName": "bbox"
     },
     {
      "modelName": "footer",
      "fieldName": "bbox"
     },
     {
      "modelName": "list",
      "fieldName": "bbox"
     },
     {
      "modelName": "paragraph",
      "fieldName": "bbox"
     },
     {
      "modelName": "reference",
      "fieldName": "bbox"
     },
     {
      "modelName": "section",
      "fieldName": "bbox"
     },
     {
      "modelName": "table",
      "fieldName": "bbox"
     },
     {
      "modelName": "title",
      "fieldName": "bbox"
     },
     {
      "modelName": "figureQuestion",
      "fieldName": "figure"
     }
    ],
    "textBox": [
     {
      "modelName": "questionAnswer",
      "fieldName": "paragraph"
     },
     {
      "modelName": "figureQuestion",
      "fieldName": "caption"
     }
    ],
    "page": [
     {
      "modelName": "ressource",
      "fieldName": "last_page"
     },
     {
      "modelName": "boundingBox",
      "fieldName": "page"
     },
     {
      "modelName": "abstract",
      "fieldName": "page"
     },
     {
      "modelName": "author",
      "fieldName": "page"
     },
     {
      "modelName": "caption",
      "fieldName": "page"
     },
     {
      "modelName": "date",
      "fieldName": "page"
     },
     {
      "modelName": "equation",
      "fieldName": "page"
     },
     {
      "modelName": "figure",
      "fieldName": "page"
     },
     {
      "modelName": "footer",
      "fieldName": "page"
     },
     {
      "modelName": "list",
      "fieldName": "page"
     },
     {
      "modelName": "paragraph",
      "fieldName": "page"
     },
     {
      "modelName": "reference",
      "fieldName": "page"
     },
     {
      "modelName": "section",
      "fieldName": "page"
     },
     {
      "modelName": "table",
      "fieldName": "page"
     },
     {
      "modelName": "title",
      "fieldName": "page"
     },
     {
      "modelName": "questionAnswer",
      "fieldName": "page"
     }
    ],
    "abstract": [],
    "author": [],
    "caption": [
     {
      "modelName": "figure",
      "fieldName": "caption"
     }
    ],
    "date": [],
    "equation": [],
    "figure": [],
    "footer": [],
    "list": [],
    "paragraph": [],
    "reference": [],
    "section": [
     {
      "modelName": "paragraph",
      "fieldName": "section"
     }
    ],
    "table": [],
    "title": [
     {
      "modelName": "paragraph",
      "fieldName": "title"
     }
    ],
    "deck": [
     {
      "modelName": "questionAnswer",
      "fieldName": "deck"
     },
     {
      "modelName": "figureQuestion",
      "fieldName": "deck"
     }
    ],
    "learningMode": [],
    "multipleChoiceDistractor": [],
    "questionAnswer": [
     {
      "modelName": "multipleChoiceDistractor",
      "fieldName": "question"
     },
     {
      "modelName": "questionAnswerFeedback",
      "fieldName": "question"
     },
     {
      "modelName": "scoringResult",
      "fieldName": "question"
     }
    ],
    "figureQuestion": [],
    "feedback": [
     {
      "modelName": "questionAnswerFeedback",
      "fieldName": "feedback"
     }
    ],
    "feedbackType": [
     {
      "modelName": "feedback",
      "fieldName": "type"
     }
    ],
    "questionAnswerFeedback": [],
    "learningStage": [
     {
      "modelName": "questionAnswer",
      "fieldName": "stage"
     },
     {
      "modelName": "scoringResult",
      "fieldName": "stage"
     }
    ],
    "scoringResult": [],
    "achievement": []
   }
   
   
   
   export const state = () => ({
       ressource:[],
       ressourceLoading : null,
       selectedRessource : null,
       label:[],
       labelLoading : null,
       selectedLabel : null,
       boundingBox:[],
       boundingBoxLoading : null,
       selectedBoundingBox : null,
       textBox:[],
       textBoxLoading : null,
       selectedTextBox : null,
       page:[],
       pageLoading : null,
       selectedPage : null,
       abstract:[],
       abstractLoading : null,
       selectedAbstract : null,
       author:[],
       authorLoading : null,
       selectedAuthor : null,
       caption:[],
       captionLoading : null,
       selectedCaption : null,
       date:[],
       dateLoading : null,
       selectedDate : null,
       equation:[],
       equationLoading : null,
       selectedEquation : null,
       figure:[],
       figureLoading : null,
       selectedFigure : null,
       footer:[],
       footerLoading : null,
       selectedFooter : null,
       list:[],
       listLoading : null,
       selectedList : null,
       paragraph:[],
       paragraphLoading : null,
       selectedParagraph : null,
       reference:[],
       referenceLoading : null,
       selectedReference : null,
       section:[],
       sectionLoading : null,
       selectedSection : null,
       table:[],
       tableLoading : null,
       selectedTable : null,
       title:[],
       titleLoading : null,
       selectedTitle : null,
       deck:[],
       deckLoading : null,
       selectedDeck : null,
       learningMode:[],
       learningModeLoading : null,
       selectedLearningMode : null,
       multipleChoiceDistractor:[],
       multipleChoiceDistractorLoading : null,
       selectedMultipleChoiceDistractor : null,
       questionAnswer:[],
       questionAnswerLoading : null,
       selectedQuestionAnswer : null,
       figureQuestion:[],
       figureQuestionLoading : null,
       selectedFigureQuestion : null,
       feedback:[],
       feedbackLoading : null,
       selectedFeedback : null,
       feedbackType:[],
       feedbackTypeLoading : null,
       selectedFeedbackType : null,
       questionAnswerFeedback:[],
       questionAnswerFeedbackLoading : null,
       selectedQuestionAnswerFeedback : null,
       learningStage:[],
       learningStageLoading : null,
       selectedLearningStage : null,
       scoringResult:[],
       scoringResultLoading : null,
       selectedScoringResult : null,
       achievement:[],
       achievementLoading : null,
       selectedAchievement : null,
       error:[],
       errorLoading : null,
       selectedError : null,
       schema:[],
       schemaLoading : null,
       selectedSchema : null,
   })
   
   export const mutations = {
   SET_RESSOURCE(state, items){ 
       state.ressource=items; 
   },
   SET_RESSOURCE_LOADING(state, val){ 
       state.ressourceLoading=val; 
   },
   UPDATE_RESSOURCE(state, item){
       const index=state.ressource.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.ressource.splice(index, 1, item)
       }
       else { state.ressource.push(item) }
   },
   INSERT_RESSOURCE(state, item){
       state.ressource.push(item)
   },
   DELETE_RESSOURCE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.ressource.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.ressource.splice(index, 1);
       if (state.selectedRessource===delItem) {    state.selectedRessource=null;}    }
   },
   SELECT_RESSOURCE(state, item){ 
       state.selectedRessource=item; 
   },
   UNSELECT_RESSOURCE(state){ 
       state.selectedRessource=null; 
   },
   
   SET_LABEL(state, items){ 
       state.label=items; 
   },
   SET_LABEL_LOADING(state, val){ 
       state.labelLoading=val; 
   },
   UPDATE_LABEL(state, item){
       const index=state.label.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.label.splice(index, 1, item)
       }
       else { state.label.push(item) }
   },
   INSERT_LABEL(state, item){
       state.label.push(item)
   },
   DELETE_LABEL(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.label.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.label.splice(index, 1);
       if (state.selectedLabel===delItem) {    state.selectedLabel=null;}    }
   },
   SELECT_LABEL(state, item){ 
       state.selectedLabel=item; 
   },
   UNSELECT_LABEL(state){ 
       state.selectedLabel=null; 
   },
   
   SET_BOUNDINGBOX(state, items){ 
       state.boundingBox=items; 
   },
   SET_BOUNDINGBOX_LOADING(state, val){ 
       state.boundingBoxLoading=val; 
   },
   UPDATE_BOUNDINGBOX(state, item){
       const index=state.boundingBox.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.boundingBox.splice(index, 1, item)
       }
       else { state.boundingBox.push(item) }
   },
   INSERT_BOUNDINGBOX(state, item){
       state.boundingBox.push(item)
   },
   DELETE_BOUNDINGBOX(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.boundingBox.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.boundingBox.splice(index, 1);
       if (state.selectedBoundingBox===delItem) {    state.selectedBoundingBox=null;}    }
   },
   SELECT_BOUNDINGBOX(state, item){ 
       state.selectedBoundingBox=item; 
   },
   UNSELECT_BOUNDINGBOX(state){ 
       state.selectedBoundingBox=null; 
   },
   
   SET_TEXTBOX(state, items){ 
       state.textBox=items; 
   },
   SET_TEXTBOX_LOADING(state, val){ 
       state.textBoxLoading=val; 
   },
   UPDATE_TEXTBOX(state, item){
       const index=state.textBox.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.textBox.splice(index, 1, item)
       }
       else { state.textBox.push(item) }
   },
   INSERT_TEXTBOX(state, item){
       state.textBox.push(item)
   },
   DELETE_TEXTBOX(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.textBox.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.textBox.splice(index, 1);
       if (state.selectedTextBox===delItem) {    state.selectedTextBox=null;}    }
   },
   SELECT_TEXTBOX(state, item){ 
       state.selectedTextBox=item; 
   },
   UNSELECT_TEXTBOX(state){ 
       state.selectedTextBox=null; 
   },
   
   SET_PAGE(state, items){ 
       state.page=items; 
   },
   SET_PAGE_LOADING(state, val){ 
       state.pageLoading=val; 
   },
   UPDATE_PAGE(state, item){
       const index=state.page.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.page.splice(index, 1, item)
       }
       else { state.page.push(item) }
   },
   INSERT_PAGE(state, item){
       state.page.push(item)
   },
   DELETE_PAGE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.page.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.page.splice(index, 1);
       if (state.selectedPage===delItem) {    state.selectedPage=null;}    }
   },
   SELECT_PAGE(state, item){ 
       state.selectedPage=item; 
   },
   UNSELECT_PAGE(state){ 
       state.selectedPage=null; 
   },
   
   SET_ABSTRACT(state, items){ 
       state.abstract=items; 
   },
   SET_ABSTRACT_LOADING(state, val){ 
       state.abstractLoading=val; 
   },
   UPDATE_ABSTRACT(state, item){
       const index=state.abstract.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.abstract.splice(index, 1, item)
       }
       else { state.abstract.push(item) }
   },
   INSERT_ABSTRACT(state, item){
       state.abstract.push(item)
   },
   DELETE_ABSTRACT(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.abstract.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.abstract.splice(index, 1);
       if (state.selectedAbstract===delItem) {    state.selectedAbstract=null;}    }
   },
   SELECT_ABSTRACT(state, item){ 
       state.selectedAbstract=item; 
   },
   UNSELECT_ABSTRACT(state){ 
       state.selectedAbstract=null; 
   },
   
   SET_AUTHOR(state, items){ 
       state.author=items; 
   },
   SET_AUTHOR_LOADING(state, val){ 
       state.authorLoading=val; 
   },
   UPDATE_AUTHOR(state, item){
       const index=state.author.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.author.splice(index, 1, item)
       }
       else { state.author.push(item) }
   },
   INSERT_AUTHOR(state, item){
       state.author.push(item)
   },
   DELETE_AUTHOR(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.author.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.author.splice(index, 1);
       if (state.selectedAuthor===delItem) {    state.selectedAuthor=null;}    }
   },
   SELECT_AUTHOR(state, item){ 
       state.selectedAuthor=item; 
   },
   UNSELECT_AUTHOR(state){ 
       state.selectedAuthor=null; 
   },
   
   SET_CAPTION(state, items){ 
       state.caption=items; 
   },
   SET_CAPTION_LOADING(state, val){ 
       state.captionLoading=val; 
   },
   UPDATE_CAPTION(state, item){
       const index=state.caption.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.caption.splice(index, 1, item)
       }
       else { state.caption.push(item) }
   },
   INSERT_CAPTION(state, item){
       state.caption.push(item)
   },
   DELETE_CAPTION(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.caption.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.caption.splice(index, 1);
       if (state.selectedCaption===delItem) {    state.selectedCaption=null;}    }
   },
   SELECT_CAPTION(state, item){ 
       state.selectedCaption=item; 
   },
   UNSELECT_CAPTION(state){ 
       state.selectedCaption=null; 
   },
   
   SET_DATE(state, items){ 
       state.date=items; 
   },
   SET_DATE_LOADING(state, val){ 
       state.dateLoading=val; 
   },
   UPDATE_DATE(state, item){
       const index=state.date.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.date.splice(index, 1, item)
       }
       else { state.date.push(item) }
   },
   INSERT_DATE(state, item){
       state.date.push(item)
   },
   DELETE_DATE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.date.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.date.splice(index, 1);
       if (state.selectedDate===delItem) {    state.selectedDate=null;}    }
   },
   SELECT_DATE(state, item){ 
       state.selectedDate=item; 
   },
   UNSELECT_DATE(state){ 
       state.selectedDate=null; 
   },
   
   SET_EQUATION(state, items){ 
       state.equation=items; 
   },
   SET_EQUATION_LOADING(state, val){ 
       state.equationLoading=val; 
   },
   UPDATE_EQUATION(state, item){
       const index=state.equation.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.equation.splice(index, 1, item)
       }
       else { state.equation.push(item) }
   },
   INSERT_EQUATION(state, item){
       state.equation.push(item)
   },
   DELETE_EQUATION(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.equation.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.equation.splice(index, 1);
       if (state.selectedEquation===delItem) {    state.selectedEquation=null;}    }
   },
   SELECT_EQUATION(state, item){ 
       state.selectedEquation=item; 
   },
   UNSELECT_EQUATION(state){ 
       state.selectedEquation=null; 
   },
   
   SET_FIGURE(state, items){ 
       state.figure=items; 
   },
   SET_FIGURE_LOADING(state, val){ 
       state.figureLoading=val; 
   },
   UPDATE_FIGURE(state, item){
       const index=state.figure.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.figure.splice(index, 1, item)
       }
       else { state.figure.push(item) }
   },
   INSERT_FIGURE(state, item){
       state.figure.push(item)
   },
   DELETE_FIGURE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.figure.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.figure.splice(index, 1);
       if (state.selectedFigure===delItem) {    state.selectedFigure=null;}    }
   },
   SELECT_FIGURE(state, item){ 
       state.selectedFigure=item; 
   },
   UNSELECT_FIGURE(state){ 
       state.selectedFigure=null; 
   },
   
   SET_FOOTER(state, items){ 
       state.footer=items; 
   },
   SET_FOOTER_LOADING(state, val){ 
       state.footerLoading=val; 
   },
   UPDATE_FOOTER(state, item){
       const index=state.footer.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.footer.splice(index, 1, item)
       }
       else { state.footer.push(item) }
   },
   INSERT_FOOTER(state, item){
       state.footer.push(item)
   },
   DELETE_FOOTER(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.footer.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.footer.splice(index, 1);
       if (state.selectedFooter===delItem) {    state.selectedFooter=null;}    }
   },
   SELECT_FOOTER(state, item){ 
       state.selectedFooter=item; 
   },
   UNSELECT_FOOTER(state){ 
       state.selectedFooter=null; 
   },
   
   SET_LIST(state, items){ 
       state.list=items; 
   },
   SET_LIST_LOADING(state, val){ 
       state.listLoading=val; 
   },
   UPDATE_LIST(state, item){
       const index=state.list.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.list.splice(index, 1, item)
       }
       else { state.list.push(item) }
   },
   INSERT_LIST(state, item){
       state.list.push(item)
   },
   DELETE_LIST(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.list.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.list.splice(index, 1);
       if (state.selectedList===delItem) {    state.selectedList=null;}    }
   },
   SELECT_LIST(state, item){ 
       state.selectedList=item; 
   },
   UNSELECT_LIST(state){ 
       state.selectedList=null; 
   },
   
   SET_PARAGRAPH(state, items){ 
       state.paragraph=items; 
   },
   SET_PARAGRAPH_LOADING(state, val){ 
       state.paragraphLoading=val; 
   },
   UPDATE_PARAGRAPH(state, item){
       const index=state.paragraph.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.paragraph.splice(index, 1, item)
       }
       else { state.paragraph.push(item) }
   },
   INSERT_PARAGRAPH(state, item){
       state.paragraph.push(item)
   },
   DELETE_PARAGRAPH(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.paragraph.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.paragraph.splice(index, 1);
       if (state.selectedParagraph===delItem) {    state.selectedParagraph=null;}    }
   },
   SELECT_PARAGRAPH(state, item){ 
       state.selectedParagraph=item; 
   },
   UNSELECT_PARAGRAPH(state){ 
       state.selectedParagraph=null; 
   },
   
   SET_REFERENCE(state, items){ 
       state.reference=items; 
   },
   SET_REFERENCE_LOADING(state, val){ 
       state.referenceLoading=val; 
   },
   UPDATE_REFERENCE(state, item){
       const index=state.reference.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.reference.splice(index, 1, item)
       }
       else { state.reference.push(item) }
   },
   INSERT_REFERENCE(state, item){
       state.reference.push(item)
   },
   DELETE_REFERENCE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.reference.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.reference.splice(index, 1);
       if (state.selectedReference===delItem) {    state.selectedReference=null;}    }
   },
   SELECT_REFERENCE(state, item){ 
       state.selectedReference=item; 
   },
   UNSELECT_REFERENCE(state){ 
       state.selectedReference=null; 
   },
   
   SET_SECTION(state, items){ 
       state.section=items; 
   },
   SET_SECTION_LOADING(state, val){ 
       state.sectionLoading=val; 
   },
   UPDATE_SECTION(state, item){
       const index=state.section.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.section.splice(index, 1, item)
       }
       else { state.section.push(item) }
   },
   INSERT_SECTION(state, item){
       state.section.push(item)
   },
   DELETE_SECTION(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.section.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.section.splice(index, 1);
       if (state.selectedSection===delItem) {    state.selectedSection=null;}    }
   },
   SELECT_SECTION(state, item){ 
       state.selectedSection=item; 
   },
   UNSELECT_SECTION(state){ 
       state.selectedSection=null; 
   },
   
   SET_TABLE(state, items){ 
       state.table=items; 
   },
   SET_TABLE_LOADING(state, val){ 
       state.tableLoading=val; 
   },
   UPDATE_TABLE(state, item){
       const index=state.table.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.table.splice(index, 1, item)
       }
       else { state.table.push(item) }
   },
   INSERT_TABLE(state, item){
       state.table.push(item)
   },
   DELETE_TABLE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.table.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.table.splice(index, 1);
       if (state.selectedTable===delItem) {    state.selectedTable=null;}    }
   },
   SELECT_TABLE(state, item){ 
       state.selectedTable=item; 
   },
   UNSELECT_TABLE(state){ 
       state.selectedTable=null; 
   },
   
   SET_TITLE(state, items){ 
       state.title=items; 
   },
   SET_TITLE_LOADING(state, val){ 
       state.titleLoading=val; 
   },
   UPDATE_TITLE(state, item){
       const index=state.title.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.title.splice(index, 1, item)
       }
       else { state.title.push(item) }
   },
   INSERT_TITLE(state, item){
       state.title.push(item)
   },
   DELETE_TITLE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.title.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.title.splice(index, 1);
       if (state.selectedTitle===delItem) {    state.selectedTitle=null;}    }
   },
   SELECT_TITLE(state, item){ 
       state.selectedTitle=item; 
   },
   UNSELECT_TITLE(state){ 
       state.selectedTitle=null; 
   },
   
   SET_DECK(state, items){ 
       state.deck=items; 
   },
   SET_DECK_LOADING(state, val){ 
       state.deckLoading=val; 
   },
   UPDATE_DECK(state, item){
       const index=state.deck.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.deck.splice(index, 1, item)
       }
       else { state.deck.push(item) }
   },
   INSERT_DECK(state, item){
       state.deck.push(item)
   },
   DELETE_DECK(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.deck.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.deck.splice(index, 1);
       if (state.selectedDeck===delItem) {    state.selectedDeck=null;}    }
   },
   SELECT_DECK(state, item){ 
       state.selectedDeck=item; 
   },
   UNSELECT_DECK(state){ 
       state.selectedDeck=null; 
   },
   
   SET_LEARNINGMODE(state, items){ 
       state.learningMode=items; 
   },
   SET_LEARNINGMODE_LOADING(state, val){ 
       state.learningModeLoading=val; 
   },
   UPDATE_LEARNINGMODE(state, item){
       const index=state.learningMode.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.learningMode.splice(index, 1, item)
       }
       else { state.learningMode.push(item) }
   },
   INSERT_LEARNINGMODE(state, item){
       state.learningMode.push(item)
   },
   DELETE_LEARNINGMODE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.learningMode.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.learningMode.splice(index, 1);
       if (state.selectedLearningMode===delItem) {    state.selectedLearningMode=null;}    }
   },
   SELECT_LEARNINGMODE(state, item){ 
       state.selectedLearningMode=item; 
   },
   UNSELECT_LEARNINGMODE(state){ 
       state.selectedLearningMode=null; 
   },
   
   SET_MULTIPLECHOICEDISTRACTOR(state, items){ 
       state.multipleChoiceDistractor=items; 
   },
   SET_MULTIPLECHOICEDISTRACTOR_LOADING(state, val){ 
       state.multipleChoiceDistractorLoading=val; 
   },
   UPDATE_MULTIPLECHOICEDISTRACTOR(state, item){
       const index=state.multipleChoiceDistractor.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.multipleChoiceDistractor.splice(index, 1, item)
       }
       else { state.multipleChoiceDistractor.push(item) }
   },
   INSERT_MULTIPLECHOICEDISTRACTOR(state, item){
       state.multipleChoiceDistractor.push(item)
   },
   DELETE_MULTIPLECHOICEDISTRACTOR(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.multipleChoiceDistractor.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.multipleChoiceDistractor.splice(index, 1);
       if (state.selectedMultipleChoiceDistractor===delItem) {    state.selectedMultipleChoiceDistractor=null;}    }
   },
   SELECT_MULTIPLECHOICEDISTRACTOR(state, item){ 
       state.selectedMultipleChoiceDistractor=item; 
   },
   UNSELECT_MULTIPLECHOICEDISTRACTOR(state){ 
       state.selectedMultipleChoiceDistractor=null; 
   },
   
   SET_QUESTIONANSWER(state, items){ 
       state.questionAnswer=items; 
   },
   SET_QUESTIONANSWER_LOADING(state, val){ 
       state.questionAnswerLoading=val; 
   },
   UPDATE_QUESTIONANSWER(state, item){
       const index=state.questionAnswer.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.questionAnswer.splice(index, 1, item)
       }
       else { state.questionAnswer.push(item) }
   },
   INSERT_QUESTIONANSWER(state, item){
       state.questionAnswer.push(item)
   },
   DELETE_QUESTIONANSWER(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.questionAnswer.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.questionAnswer.splice(index, 1);
       if (state.selectedQuestionAnswer===delItem) {    state.selectedQuestionAnswer=null;}    }
   },
   SELECT_QUESTIONANSWER(state, item){ 
       state.selectedQuestionAnswer=item; 
   },
   UNSELECT_QUESTIONANSWER(state){ 
       state.selectedQuestionAnswer=null; 
   },
   
   SET_FIGUREQUESTION(state, items){ 
       state.figureQuestion=items; 
   },
   SET_FIGUREQUESTION_LOADING(state, val){ 
       state.figureQuestionLoading=val; 
   },
   UPDATE_FIGUREQUESTION(state, item){
       const index=state.figureQuestion.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.figureQuestion.splice(index, 1, item)
       }
       else { state.figureQuestion.push(item) }
   },
   INSERT_FIGUREQUESTION(state, item){
       state.figureQuestion.push(item)
   },
   DELETE_FIGUREQUESTION(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.figureQuestion.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.figureQuestion.splice(index, 1);
       if (state.selectedFigureQuestion===delItem) {    state.selectedFigureQuestion=null;}    }
   },
   SELECT_FIGUREQUESTION(state, item){ 
       state.selectedFigureQuestion=item; 
   },
   UNSELECT_FIGUREQUESTION(state){ 
       state.selectedFigureQuestion=null; 
   },
   
   SET_FEEDBACK(state, items){ 
       state.feedback=items; 
   },
   SET_FEEDBACK_LOADING(state, val){ 
       state.feedbackLoading=val; 
   },
   UPDATE_FEEDBACK(state, item){
       const index=state.feedback.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.feedback.splice(index, 1, item)
       }
       else { state.feedback.push(item) }
   },
   INSERT_FEEDBACK(state, item){
       state.feedback.push(item)
   },
   DELETE_FEEDBACK(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.feedback.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.feedback.splice(index, 1);
       if (state.selectedFeedback===delItem) {    state.selectedFeedback=null;}    }
   },
   SELECT_FEEDBACK(state, item){ 
       state.selectedFeedback=item; 
   },
   UNSELECT_FEEDBACK(state){ 
       state.selectedFeedback=null; 
   },
   
   SET_FEEDBACKTYPE(state, items){ 
       state.feedbackType=items; 
   },
   SET_FEEDBACKTYPE_LOADING(state, val){ 
       state.feedbackTypeLoading=val; 
   },
   UPDATE_FEEDBACKTYPE(state, item){
       const index=state.feedbackType.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.feedbackType.splice(index, 1, item)
       }
       else { state.feedbackType.push(item) }
   },
   INSERT_FEEDBACKTYPE(state, item){
       state.feedbackType.push(item)
   },
   DELETE_FEEDBACKTYPE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.feedbackType.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.feedbackType.splice(index, 1);
       if (state.selectedFeedbackType===delItem) {    state.selectedFeedbackType=null;}    }
   },
   SELECT_FEEDBACKTYPE(state, item){ 
       state.selectedFeedbackType=item; 
   },
   UNSELECT_FEEDBACKTYPE(state){ 
       state.selectedFeedbackType=null; 
   },
   
   SET_QUESTIONANSWERFEEDBACK(state, items){ 
       state.questionAnswerFeedback=items; 
   },
   SET_QUESTIONANSWERFEEDBACK_LOADING(state, val){ 
       state.questionAnswerFeedbackLoading=val; 
   },
   UPDATE_QUESTIONANSWERFEEDBACK(state, item){
       const index=state.questionAnswerFeedback.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.questionAnswerFeedback.splice(index, 1, item)
       }
       else { state.questionAnswerFeedback.push(item) }
   },
   INSERT_QUESTIONANSWERFEEDBACK(state, item){
       state.questionAnswerFeedback.push(item)
   },
   DELETE_QUESTIONANSWERFEEDBACK(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.questionAnswerFeedback.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.questionAnswerFeedback.splice(index, 1);
       if (state.selectedQuestionAnswerFeedback===delItem) {    state.selectedQuestionAnswerFeedback=null;}    }
   },
   SELECT_QUESTIONANSWERFEEDBACK(state, item){ 
       state.selectedQuestionAnswerFeedback=item; 
   },
   UNSELECT_QUESTIONANSWERFEEDBACK(state){ 
       state.selectedQuestionAnswerFeedback=null; 
   },
   
   SET_LEARNINGSTAGE(state, items){ 
       state.learningStage=items; 
   },
   SET_LEARNINGSTAGE_LOADING(state, val){ 
       state.learningStageLoading=val; 
   },
   UPDATE_LEARNINGSTAGE(state, item){
       const index=state.learningStage.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.learningStage.splice(index, 1, item)
       }
       else { state.learningStage.push(item) }
   },
   INSERT_LEARNINGSTAGE(state, item){
       state.learningStage.push(item)
   },
   DELETE_LEARNINGSTAGE(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.learningStage.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.learningStage.splice(index, 1);
       if (state.selectedLearningStage===delItem) {    state.selectedLearningStage=null;}    }
   },
   SELECT_LEARNINGSTAGE(state, item){ 
       state.selectedLearningStage=item; 
   },
   UNSELECT_LEARNINGSTAGE(state){ 
       state.selectedLearningStage=null; 
   },
   
   SET_SCORINGRESULT(state, items){ 
       state.scoringResult=items; 
   },
   SET_SCORINGRESULT_LOADING(state, val){ 
       state.scoringResultLoading=val; 
   },
   UPDATE_SCORINGRESULT(state, item){
       const index=state.scoringResult.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.scoringResult.splice(index, 1, item)
       }
       else { state.scoringResult.push(item) }
   },
   INSERT_SCORINGRESULT(state, item){
       state.scoringResult.push(item)
   },
   DELETE_SCORINGRESULT(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.scoringResult.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.scoringResult.splice(index, 1);
       if (state.selectedScoringResult===delItem) {    state.selectedScoringResult=null;}    }
   },
   SELECT_SCORINGRESULT(state, item){ 
       state.selectedScoringResult=item; 
   },
   UNSELECT_SCORINGRESULT(state){ 
       state.selectedScoringResult=null; 
   },
   
   SET_ACHIEVEMENT(state, items){ 
       state.achievement=items; 
   },
   SET_ACHIEVEMENT_LOADING(state, val){ 
       state.achievementLoading=val; 
   },
   UPDATE_ACHIEVEMENT(state, item){
       const index=state.achievement.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.achievement.splice(index, 1, item)
       }
       else { state.achievement.push(item) }
   },
   INSERT_ACHIEVEMENT(state, item){
       state.achievement.push(item)
   },
   DELETE_ACHIEVEMENT(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.achievement.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.achievement.splice(index, 1);
       if (state.selectedAchievement===delItem) {    state.selectedAchievement=null;}    }
   },
   SELECT_ACHIEVEMENT(state, item){ 
       state.selectedAchievement=item; 
   },
   UNSELECT_ACHIEVEMENT(state){ 
       state.selectedAchievement=null; 
   },
   
   SET_ERROR(state, items){ 
       state.error=items; 
   },
   SET_ERROR_LOADING(state, val){ 
       state.errorLoading=val; 
   },
   UPDATE_ERROR(state, item){
       const index=state.error.findIndex(element => element.id === item.id);
       if (index !== -1) {
       state.error.splice(index, 1, item)
       }
       else { state.error.push(item) }
   },
   INSERT_ERROR(state, item){
       state.error.push(item)
   },
   DELETE_ERROR(state, item){
       let delItem = Number.isInteger(item) ? item : item.id
       const index=state.error.findIndex(element => element.id === delItem);
       if (index !== -1) {
       state.error.splice(index, 1);
       if (state.selectedError===delItem) {    state.selectedError=null;}    }
   },
   SELECT_ERROR(state, item){ 
       state.selectedError=item; 
   },
   UNSELECT_ERROR(state){ 
       state.selectedError=null; 
   },
   
   SET_SCHEMA(state, item){ 
        const YAML = require('yaml'); 
        state.schema = YAML.parse(item);}
   
   }
   
   export const getters = {
   
   getLoadingState: state => requirements => {
        var loadingList=[]
        for (var req in requirements) {
             const field = req+"Loading";
             if (state[field]==true | state[field]==null){
                  loadingList.push(req)
             }
        }
   return loadingList  
   },
   
   filterCustomUserRessource_user: state => {
       if (state.selectedCustomUser){
           return state.ressource.filter(data => data.user === state.selectedCustomUser)
       } else {
           return null
       }
   },
   
   filterPageRessource_last_page: state => {
       if (state.selectedPage){
           return state.ressource.filter(data => data.last_page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterPageBoundingBox_page: state => {
       if (state.selectedPage){
           return state.boundingBox.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterLabelBoundingBox_label: state => {
       if (state.selectedLabel){
           return state.boundingBox.filter(data => data.label === state.selectedLabel)
       } else {
           return null
       }
   },
   
   filterRessourcePage_ressource: state => {
       if (state.selectedRessource){
           return state.page.filter(data => data.ressource === state.selectedRessource)
       } else {
           return null
       }
   },
   
   filterCustomUserPage_user: state => {
       if (state.selectedCustomUser){
           return state.page.filter(data => data.user === state.selectedCustomUser)
       } else {
           return null
       }
   },
   
   filterPageAbstract_page: state => {
       if (state.selectedPage){
           return state.abstract.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxAbstract_bbox: state => {
       if (state.selectedBoundingBox){
           return state.abstract.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageAuthor_page: state => {
       if (state.selectedPage){
           return state.author.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxAuthor_bbox: state => {
       if (state.selectedBoundingBox){
           return state.author.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageCaption_page: state => {
       if (state.selectedPage){
           return state.caption.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxCaption_bbox: state => {
       if (state.selectedBoundingBox){
           return state.caption.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageDate_page: state => {
       if (state.selectedPage){
           return state.date.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxDate_bbox: state => {
       if (state.selectedBoundingBox){
           return state.date.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageEquation_page: state => {
       if (state.selectedPage){
           return state.equation.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxEquation_bbox: state => {
       if (state.selectedBoundingBox){
           return state.equation.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterCaptionFigure_caption: state => {
       if (state.selectedCaption){
           return state.figure.filter(data => data.caption === state.selectedCaption)
       } else {
           return null
       }
   },
   
   filterPageFigure_page: state => {
       if (state.selectedPage){
           return state.figure.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxFigure_bbox: state => {
       if (state.selectedBoundingBox){
           return state.figure.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageFooter_page: state => {
       if (state.selectedPage){
           return state.footer.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxFooter_bbox: state => {
       if (state.selectedBoundingBox){
           return state.footer.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageList_page: state => {
       if (state.selectedPage){
           return state.list.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxList_bbox: state => {
       if (state.selectedBoundingBox){
           return state.list.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterTitleParagraph_title: state => {
       if (state.selectedTitle){
           return state.paragraph.filter(data => data.title === state.selectedTitle)
       } else {
           return null
       }
   },
   
   filterSectionParagraph_section: state => {
       if (state.selectedSection){
           return state.paragraph.filter(data => data.section === state.selectedSection)
       } else {
           return null
       }
   },
   
   filterPageParagraph_page: state => {
       if (state.selectedPage){
           return state.paragraph.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxParagraph_bbox: state => {
       if (state.selectedBoundingBox){
           return state.paragraph.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageReference_page: state => {
       if (state.selectedPage){
           return state.reference.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxReference_bbox: state => {
       if (state.selectedBoundingBox){
           return state.reference.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageSection_page: state => {
       if (state.selectedPage){
           return state.section.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxSection_bbox: state => {
       if (state.selectedBoundingBox){
           return state.section.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageTable_page: state => {
       if (state.selectedPage){
           return state.table.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxTable_bbox: state => {
       if (state.selectedBoundingBox){
           return state.table.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterPageTitle_page: state => {
       if (state.selectedPage){
           return state.title.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxTitle_bbox: state => {
       if (state.selectedBoundingBox){
           return state.title.filter(data => data.bbox === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterRessourceDeck_ressource: state => {
       if (state.selectedRessource){
           return state.deck.filter(data => data.ressource === state.selectedRessource)
       } else {
           return null
       }
   },
   
   filterCustomUserDeck_user: state => {
       if (state.selectedCustomUser){
           return state.deck.filter(data => data.user === state.selectedCustomUser)
       } else {
           return null
       }
   },
   
   filterQuestionAnswerMultipleChoiceDistractor_question: state => {
       if (state.selectedQuestionAnswer){
           return state.multipleChoiceDistractor.filter(data => data.question === state.selectedQuestionAnswer)
       } else {
           return null
       }
   },
   
   filterDeckQuestionAnswer_deck: state => {
       if (state.selectedDeck){
           return state.questionAnswer.filter(data => data.deck === state.selectedDeck)
       } else {
           return null
       }
   },
   
   filterCustomUserQuestionAnswer_user: state => {
       if (state.selectedCustomUser){
           return state.questionAnswer.filter(data => data.user === state.selectedCustomUser)
       } else {
           return null
       }
   },
   
   filterLearningStageQuestionAnswer_stage: state => {
       if (state.selectedLearningStage){
           return state.questionAnswer.filter(data => data.stage === state.selectedLearningStage)
       } else {
           return null
       }
   },
   
   filterTextBoxQuestionAnswer_paragraph: state => {
       if (state.selectedTextBox){
           return state.questionAnswer.filter(data => data.paragraph === state.selectedTextBox)
       } else {
           return null
       }
   },
   
   filterPageQuestionAnswer_page: state => {
       if (state.selectedPage){
           return state.questionAnswer.filter(data => data.page === state.selectedPage)
       } else {
           return null
       }
   },
   
   filterBoundingBoxFigureQuestion_figure: state => {
       if (state.selectedBoundingBox){
           return state.figureQuestion.filter(data => data.figure === state.selectedBoundingBox)
       } else {
           return null
       }
   },
   
   filterTextBoxFigureQuestion_caption: state => {
       if (state.selectedTextBox){
           return state.figureQuestion.filter(data => data.caption === state.selectedTextBox)
       } else {
           return null
       }
   },
   
   filterCustomUserFigureQuestion_user: state => {
       if (state.selectedCustomUser){
           return state.figureQuestion.filter(data => data.user === state.selectedCustomUser)
       } else {
           return null
       }
   },
   
   filterDeckFigureQuestion_deck: state => {
       if (state.selectedDeck){
           return state.figureQuestion.filter(data => data.deck === state.selectedDeck)
       } else {
           return null
       }
   },
   
   filterFeedbackTypeFeedback_type: state => {
       if (state.selectedFeedbackType){
           return state.feedback.filter(data => data.type === state.selectedFeedbackType)
       } else {
           return null
       }
   },
   
   filterQuestionAnswerQuestionAnswerFeedback_question: state => {
       if (state.selectedQuestionAnswer){
           return state.questionAnswerFeedback.filter(data => data.question === state.selectedQuestionAnswer)
       } else {
           return null
       }
   },
   
   filterFeedbackQuestionAnswerFeedback_feedback: state => {
       if (state.selectedFeedback){
           return state.questionAnswerFeedback.filter(data => data.feedback === state.selectedFeedback)
       } else {
           return null
       }
   },
   
   filterCustomUserQuestionAnswerFeedback_user: state => {
       if (state.selectedCustomUser){
           return state.questionAnswerFeedback.filter(data => data.user === state.selectedCustomUser)
       } else {
           return null
       }
   },
   
   filterQuestionAnswerScoringResult_question: state => {
       if (state.selectedQuestionAnswer){
           return state.scoringResult.filter(data => data.question === state.selectedQuestionAnswer)
       } else {
           return null
       }
   },
   
   filterLearningStageScoringResult_stage: state => {
       if (state.selectedLearningStage){
           return state.scoringResult.filter(data => data.stage === state.selectedLearningStage)
       } else {
           return null
       }
   },
   
   filterCustomUserScoringResult_user: state => {
       if (state.selectedCustomUser){
           return state.scoringResult.filter(data => data.user === state.selectedCustomUser)
       } else {
           return null
       }
   },
   
   getSelectedRessource: state => {
       if (state.selectedRessource){
           return state.ressource.filter(data => data.id === state.selectedRessource)[0]
       } else {
           return null
       }
       },
   
   getSelectedLabel: state => {
       if (state.selectedLabel){
           return state.label.filter(data => data.id === state.selectedLabel)[0]
       } else {
           return null
       }
       },
   
   getSelectedBoundingBox: state => {
       if (state.selectedBoundingBox){
           return state.boundingBox.filter(data => data.id === state.selectedBoundingBox)[0]
       } else {
           return null
       }
       },
   
   getSelectedTextBox: state => {
       if (state.selectedTextBox){
           return state.textBox.filter(data => data.id === state.selectedTextBox)[0]
       } else {
           return null
       }
       },
   
   getSelectedPage: state => {
       if (state.selectedPage){
           return state.page.filter(data => data.id === state.selectedPage)[0]
       } else {
           return null
       }
       },
   
   getSelectedAbstract: state => {
       if (state.selectedAbstract){
           return state.abstract.filter(data => data.id === state.selectedAbstract)[0]
       } else {
           return null
       }
       },
   
   getSelectedAuthor: state => {
       if (state.selectedAuthor){
           return state.author.filter(data => data.id === state.selectedAuthor)[0]
       } else {
           return null
       }
       },
   
   getSelectedCaption: state => {
       if (state.selectedCaption){
           return state.caption.filter(data => data.id === state.selectedCaption)[0]
       } else {
           return null
       }
       },
   
   getSelectedDate: state => {
       if (state.selectedDate){
           return state.date.filter(data => data.id === state.selectedDate)[0]
       } else {
           return null
       }
       },
   
   getSelectedEquation: state => {
       if (state.selectedEquation){
           return state.equation.filter(data => data.id === state.selectedEquation)[0]
       } else {
           return null
       }
       },
   
   getSelectedFigure: state => {
       if (state.selectedFigure){
           return state.figure.filter(data => data.id === state.selectedFigure)[0]
       } else {
           return null
       }
       },
   
   getSelectedFooter: state => {
       if (state.selectedFooter){
           return state.footer.filter(data => data.id === state.selectedFooter)[0]
       } else {
           return null
       }
       },
   
   getSelectedList: state => {
       if (state.selectedList){
           return state.list.filter(data => data.id === state.selectedList)[0]
       } else {
           return null
       }
       },
   
   getSelectedParagraph: state => {
       if (state.selectedParagraph){
           return state.paragraph.filter(data => data.id === state.selectedParagraph)[0]
       } else {
           return null
       }
       },
   
   getSelectedReference: state => {
       if (state.selectedReference){
           return state.reference.filter(data => data.id === state.selectedReference)[0]
       } else {
           return null
       }
       },
   
   getSelectedSection: state => {
       if (state.selectedSection){
           return state.section.filter(data => data.id === state.selectedSection)[0]
       } else {
           return null
       }
       },
   
   getSelectedTable: state => {
       if (state.selectedTable){
           return state.table.filter(data => data.id === state.selectedTable)[0]
       } else {
           return null
       }
       },
   
   getSelectedTitle: state => {
       if (state.selectedTitle){
           return state.title.filter(data => data.id === state.selectedTitle)[0]
       } else {
           return null
       }
       },
   
   getSelectedDeck: state => {
       if (state.selectedDeck){
           return state.deck.filter(data => data.id === state.selectedDeck)[0]
       } else {
           return null
       }
       },
   
   getSelectedLearningMode: state => {
       if (state.selectedLearningMode){
           return state.learningMode.filter(data => data.id === state.selectedLearningMode)[0]
       } else {
           return null
       }
       },
   
   getSelectedMultipleChoiceDistractor: state => {
       if (state.selectedMultipleChoiceDistractor){
           return state.multipleChoiceDistractor.filter(data => data.id === state.selectedMultipleChoiceDistractor)[0]
       } else {
           return null
       }
       },
   
   getSelectedQuestionAnswer: state => {
       if (state.selectedQuestionAnswer){
           return state.questionAnswer.filter(data => data.id === state.selectedQuestionAnswer)[0]
       } else {
           return null
       }
       },
   
   getSelectedFigureQuestion: state => {
       if (state.selectedFigureQuestion){
           return state.figureQuestion.filter(data => data.id === state.selectedFigureQuestion)[0]
       } else {
           return null
       }
       },
   
   getSelectedFeedback: state => {
       if (state.selectedFeedback){
           return state.feedback.filter(data => data.id === state.selectedFeedback)[0]
       } else {
           return null
       }
       },
   
   getSelectedFeedbackType: state => {
       if (state.selectedFeedbackType){
           return state.feedbackType.filter(data => data.id === state.selectedFeedbackType)[0]
       } else {
           return null
       }
       },
   
   getSelectedQuestionAnswerFeedback: state => {
       if (state.selectedQuestionAnswerFeedback){
           return state.questionAnswerFeedback.filter(data => data.id === state.selectedQuestionAnswerFeedback)[0]
       } else {
           return null
       }
       },
   
   getSelectedLearningStage: state => {
       if (state.selectedLearningStage){
           return state.learningStage.filter(data => data.id === state.selectedLearningStage)[0]
       } else {
           return null
       }
       },
   
   getSelectedScoringResult: state => {
       if (state.selectedScoringResult){
           return state.scoringResult.filter(data => data.id === state.selectedScoringResult)[0]
       } else {
           return null
       }
       },
   
   getSelectedAchievement: state => {
       if (state.selectedAchievement){
           return state.achievement.filter(data => data.id === state.selectedAchievement)[0]
       } else {
           return null
       }
       },
   
   getRessourceById: state => id => {
       return state.ressource.filter(data => data.id === id)[0]
       },
   
   getRessourceByIds: state => ids => {
       return state.ressource.filter(data => ids.includes(data.id))
       },
   
   getLabelById: state => id => {
       return state.label.filter(data => data.id === id)[0]
       },
   
   getLabelByIds: state => ids => {
       return state.label.filter(data => ids.includes(data.id))
       },
   
   getBoundingBoxById: state => id => {
       return state.boundingBox.filter(data => data.id === id)[0]
       },
   
   getBoundingBoxByIds: state => ids => {
       return state.boundingBox.filter(data => ids.includes(data.id))
       },
   
   getTextBoxById: state => id => {
       return state.textBox.filter(data => data.id === id)[0]
       },
   
   getTextBoxByIds: state => ids => {
       return state.textBox.filter(data => ids.includes(data.id))
       },
   
   getPageById: state => id => {
       return state.page.filter(data => data.id === id)[0]
       },
   
   getPageByIds: state => ids => {
       return state.page.filter(data => ids.includes(data.id))
       },
   
   getAbstractById: state => id => {
       return state.abstract.filter(data => data.id === id)[0]
       },
   
   getAbstractByIds: state => ids => {
       return state.abstract.filter(data => ids.includes(data.id))
       },
   
   getAuthorById: state => id => {
       return state.author.filter(data => data.id === id)[0]
       },
   
   getAuthorByIds: state => ids => {
       return state.author.filter(data => ids.includes(data.id))
       },
   
   getCaptionById: state => id => {
       return state.caption.filter(data => data.id === id)[0]
       },
   
   getCaptionByIds: state => ids => {
       return state.caption.filter(data => ids.includes(data.id))
       },
   
   getDateById: state => id => {
       return state.date.filter(data => data.id === id)[0]
       },
   
   getDateByIds: state => ids => {
       return state.date.filter(data => ids.includes(data.id))
       },
   
   getEquationById: state => id => {
       return state.equation.filter(data => data.id === id)[0]
       },
   
   getEquationByIds: state => ids => {
       return state.equation.filter(data => ids.includes(data.id))
       },
   
   getFigureById: state => id => {
       return state.figure.filter(data => data.id === id)[0]
       },
   
   getFigureByIds: state => ids => {
       return state.figure.filter(data => ids.includes(data.id))
       },
   
   getFooterById: state => id => {
       return state.footer.filter(data => data.id === id)[0]
       },
   
   getFooterByIds: state => ids => {
       return state.footer.filter(data => ids.includes(data.id))
       },
   
   getListById: state => id => {
       return state.list.filter(data => data.id === id)[0]
       },
   
   getListByIds: state => ids => {
       return state.list.filter(data => ids.includes(data.id))
       },
   
   getParagraphById: state => id => {
       return state.paragraph.filter(data => data.id === id)[0]
       },
   
   getParagraphByIds: state => ids => {
       return state.paragraph.filter(data => ids.includes(data.id))
       },
   
   getReferenceById: state => id => {
       return state.reference.filter(data => data.id === id)[0]
       },
   
   getReferenceByIds: state => ids => {
       return state.reference.filter(data => ids.includes(data.id))
       },
   
   getSectionById: state => id => {
       return state.section.filter(data => data.id === id)[0]
       },
   
   getSectionByIds: state => ids => {
       return state.section.filter(data => ids.includes(data.id))
       },
   
   getTableById: state => id => {
       return state.table.filter(data => data.id === id)[0]
       },
   
   getTableByIds: state => ids => {
       return state.table.filter(data => ids.includes(data.id))
       },
   
   getTitleById: state => id => {
       return state.title.filter(data => data.id === id)[0]
       },
   
   getTitleByIds: state => ids => {
       return state.title.filter(data => ids.includes(data.id))
       },
   
   getDeckById: state => id => {
       return state.deck.filter(data => data.id === id)[0]
       },
   
   getDeckByIds: state => ids => {
       return state.deck.filter(data => ids.includes(data.id))
       },
   
   getLearningModeById: state => id => {
       return state.learningMode.filter(data => data.id === id)[0]
       },
   
   getLearningModeByIds: state => ids => {
       return state.learningMode.filter(data => ids.includes(data.id))
       },
   
   getMultipleChoiceDistractorById: state => id => {
       return state.multipleChoiceDistractor.filter(data => data.id === id)[0]
       },
   
   getMultipleChoiceDistractorByIds: state => ids => {
       return state.multipleChoiceDistractor.filter(data => ids.includes(data.id))
       },
   
   getQuestionAnswerById: state => id => {
       return state.questionAnswer.filter(data => data.id === id)[0]
       },
   
   getQuestionAnswerByIds: state => ids => {
       return state.questionAnswer.filter(data => ids.includes(data.id))
       },
   
   getFigureQuestionById: state => id => {
       return state.figureQuestion.filter(data => data.id === id)[0]
       },
   
   getFigureQuestionByIds: state => ids => {
       return state.figureQuestion.filter(data => ids.includes(data.id))
       },
   
   getFeedbackById: state => id => {
       return state.feedback.filter(data => data.id === id)[0]
       },
   
   getFeedbackByIds: state => ids => {
       return state.feedback.filter(data => ids.includes(data.id))
       },
   
   getFeedbackTypeById: state => id => {
       return state.feedbackType.filter(data => data.id === id)[0]
       },
   
   getFeedbackTypeByIds: state => ids => {
       return state.feedbackType.filter(data => ids.includes(data.id))
       },
   
   getQuestionAnswerFeedbackById: state => id => {
       return state.questionAnswerFeedback.filter(data => data.id === id)[0]
       },
   
   getQuestionAnswerFeedbackByIds: state => ids => {
       return state.questionAnswerFeedback.filter(data => ids.includes(data.id))
       },
   
   getLearningStageById: state => id => {
       return state.learningStage.filter(data => data.id === id)[0]
       },
   
   getLearningStageByIds: state => ids => {
       return state.learningStage.filter(data => ids.includes(data.id))
       },
   
   getScoringResultById: state => id => {
       return state.scoringResult.filter(data => data.id === id)[0]
       },
   
   getScoringResultByIds: state => ids => {
       return state.scoringResult.filter(data => ids.includes(data.id))
       },
   
   getAchievementById: state => id => {
       return state.achievement.filter(data => data.id === id)[0]
       },
   
   getAchievementByIds: state => ids => {
       return state.achievement.filter(data => ids.includes(data.id))
       },
   }
   
   export const actions = {
   async getRessourceList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_RESSOURCE_LOADING', true)
       const endpoint = 'ressource/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_RESSOURCE', data);
            commit('SET_RESSOURCE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_RESSOURCE_LOADING', false);
            reject(error)
       })
       })
   },
   async getRessource({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'ressource/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_RESSOURCE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postRessource({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'ressource/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_RESSOURCE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putRessource({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'ressource/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_RESSOURCE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteRessource({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'ressource/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_RESSOURCE', item);
            fkRels.ressource.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getLabelList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_LABEL_LOADING', true)
       const endpoint = 'label/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_LABEL', data);
            commit('SET_LABEL_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_LABEL_LOADING', false);
            reject(error)
       })
       })
   },
   async getLabel({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'label/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_LABEL', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postLabel({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'label/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_LABEL', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putLabel({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'label/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_LABEL', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteLabel({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'label/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_LABEL', item);
            fkRels.label.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getBoundingBoxList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_BOUNDINGBOX_LOADING', true)
       const endpoint = 'boundingbox/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_BOUNDINGBOX', data);
            commit('SET_BOUNDINGBOX_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_BOUNDINGBOX_LOADING', false);
            reject(error)
       })
       })
   },
   async getBoundingBox({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'boundingbox/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_BOUNDINGBOX', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postBoundingBox({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'boundingbox/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_BOUNDINGBOX', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putBoundingBox({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'boundingbox/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_BOUNDINGBOX', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteBoundingBox({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'boundingbox/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_BOUNDINGBOX', item);
            fkRels.boundingBox.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getTextBoxList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_TEXTBOX_LOADING', true)
       const endpoint = 'textbox/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_TEXTBOX', data);
            commit('SET_TEXTBOX_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_TEXTBOX_LOADING', false);
            reject(error)
       })
       })
   },
   async getTextBox({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'textbox/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_TEXTBOX', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postTextBox({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'textbox/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_TEXTBOX', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putTextBox({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'textbox/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_TEXTBOX', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteTextBox({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'textbox/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_TEXTBOX', item);
            fkRels.textBox.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getPageList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_PAGE_LOADING', true)
       const endpoint = 'page/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_PAGE', data);
            commit('SET_PAGE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_PAGE_LOADING', false);
            reject(error)
       })
       })
   },
   async getPage({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'page/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_PAGE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postPage({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'page/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_PAGE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putPage({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'page/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_PAGE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deletePage({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'page/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_PAGE', item);
            fkRels.page.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getAbstractList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_ABSTRACT_LOADING', true)
       const endpoint = 'abstract/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_ABSTRACT', data);
            commit('SET_ABSTRACT_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_ABSTRACT_LOADING', false);
            reject(error)
       })
       })
   },
   async getAbstract({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'abstract/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_ABSTRACT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postAbstract({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'abstract/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_ABSTRACT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putAbstract({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'abstract/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_ABSTRACT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteAbstract({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'abstract/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_ABSTRACT', item);
            fkRels.abstract.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getAuthorList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_AUTHOR_LOADING', true)
       const endpoint = 'author/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_AUTHOR', data);
            commit('SET_AUTHOR_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_AUTHOR_LOADING', false);
            reject(error)
       })
       })
   },
   async getAuthor({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'author/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_AUTHOR', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postAuthor({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'author/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_AUTHOR', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putAuthor({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'author/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_AUTHOR', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteAuthor({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'author/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_AUTHOR', item);
            fkRels.author.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getCaptionList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_CAPTION_LOADING', true)
       const endpoint = 'caption/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_CAPTION', data);
            commit('SET_CAPTION_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_CAPTION_LOADING', false);
            reject(error)
       })
       })
   },
   async getCaption({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'caption/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_CAPTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postCaption({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'caption/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_CAPTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putCaption({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'caption/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_CAPTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteCaption({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'caption/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_CAPTION', item);
            fkRels.caption.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getDateList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_DATE_LOADING', true)
       const endpoint = 'date/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_DATE', data);
            commit('SET_DATE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_DATE_LOADING', false);
            reject(error)
       })
       })
   },
   async getDate({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'date/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_DATE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postDate({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'date/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_DATE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putDate({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'date/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_DATE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteDate({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'date/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_DATE', item);
            fkRels.date.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getEquationList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_EQUATION_LOADING', true)
       const endpoint = 'equation/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_EQUATION', data);
            commit('SET_EQUATION_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_EQUATION_LOADING', false);
            reject(error)
       })
       })
   },
   async getEquation({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'equation/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_EQUATION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postEquation({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'equation/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_EQUATION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putEquation({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'equation/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_EQUATION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteEquation({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'equation/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_EQUATION', item);
            fkRels.equation.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getFigureList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_FIGURE_LOADING', true)
       const endpoint = 'figure/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_FIGURE', data);
            commit('SET_FIGURE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_FIGURE_LOADING', false);
            reject(error)
       })
       })
   },
   async getFigure({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'figure/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_FIGURE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postFigure({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'figure/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_FIGURE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putFigure({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'figure/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_FIGURE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteFigure({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'figure/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_FIGURE', item);
            fkRels.figure.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getFooterList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_FOOTER_LOADING', true)
       const endpoint = 'footer/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_FOOTER', data);
            commit('SET_FOOTER_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_FOOTER_LOADING', false);
            reject(error)
       })
       })
   },
   async getFooter({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'footer/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_FOOTER', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postFooter({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'footer/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_FOOTER', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putFooter({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'footer/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_FOOTER', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteFooter({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'footer/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_FOOTER', item);
            fkRels.footer.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getListList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_LIST_LOADING', true)
       const endpoint = 'list/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_LIST', data);
            commit('SET_LIST_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_LIST_LOADING', false);
            reject(error)
       })
       })
   },
   async getList({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'list/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_LIST', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postList({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'list/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_LIST', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putList({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'list/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_LIST', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteList({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'list/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_LIST', item);
            fkRels.list.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getParagraphList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_PARAGRAPH_LOADING', true)
       const endpoint = 'paragraph/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_PARAGRAPH', data);
            commit('SET_PARAGRAPH_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_PARAGRAPH_LOADING', false);
            reject(error)
       })
       })
   },
   async getParagraph({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'paragraph/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_PARAGRAPH', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postParagraph({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'paragraph/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_PARAGRAPH', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putParagraph({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'paragraph/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_PARAGRAPH', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteParagraph({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'paragraph/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_PARAGRAPH', item);
            fkRels.paragraph.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getReferenceList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_REFERENCE_LOADING', true)
       const endpoint = 'reference/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_REFERENCE', data);
            commit('SET_REFERENCE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_REFERENCE_LOADING', false);
            reject(error)
       })
       })
   },
   async getReference({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'reference/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_REFERENCE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postReference({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'reference/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_REFERENCE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putReference({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'reference/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_REFERENCE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteReference({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'reference/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_REFERENCE', item);
            fkRels.reference.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getSectionList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_SECTION_LOADING', true)
       const endpoint = 'section/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_SECTION', data);
            commit('SET_SECTION_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_SECTION_LOADING', false);
            reject(error)
       })
       })
   },
   async getSection({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'section/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_SECTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postSection({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'section/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_SECTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putSection({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'section/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_SECTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteSection({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'section/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_SECTION', item);
            fkRels.section.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getTableList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_TABLE_LOADING', true)
       const endpoint = 'table/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_TABLE', data);
            commit('SET_TABLE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_TABLE_LOADING', false);
            reject(error)
       })
       })
   },
   async getTable({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'table/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_TABLE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postTable({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'table/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_TABLE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putTable({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'table/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_TABLE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteTable({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'table/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_TABLE', item);
            fkRels.table.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getTitleList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_TITLE_LOADING', true)
       const endpoint = 'title/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_TITLE', data);
            commit('SET_TITLE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_TITLE_LOADING', false);
            reject(error)
       })
       })
   },
   async getTitle({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'title/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_TITLE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postTitle({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'title/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_TITLE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putTitle({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'title/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_TITLE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteTitle({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'title/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_TITLE', item);
            fkRels.title.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getDeckList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_DECK_LOADING', true)
       const endpoint = 'deck/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_DECK', data);
            commit('SET_DECK_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_DECK_LOADING', false);
            reject(error)
       })
       })
   },
   async getDeck({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'deck/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_DECK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postDeck({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'deck/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_DECK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putDeck({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'deck/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_DECK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteDeck({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'deck/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_DECK', item);
            fkRels.deck.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getLearningModeList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_LEARNINGMODE_LOADING', true)
       const endpoint = 'learningmode/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_LEARNINGMODE', data);
            commit('SET_LEARNINGMODE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_LEARNINGMODE_LOADING', false);
            reject(error)
       })
       })
   },
   async getLearningMode({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'learningmode/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_LEARNINGMODE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postLearningMode({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'learningmode/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_LEARNINGMODE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putLearningMode({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'learningmode/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_LEARNINGMODE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteLearningMode({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'learningmode/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_LEARNINGMODE', item);
            fkRels.learningMode.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getMultipleChoiceDistractorList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_MULTIPLECHOICEDISTRACTOR_LOADING', true)
       const endpoint = 'multiplechoicedistractor/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_MULTIPLECHOICEDISTRACTOR', data);
            commit('SET_MULTIPLECHOICEDISTRACTOR_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_MULTIPLECHOICEDISTRACTOR_LOADING', false);
            reject(error)
       })
       })
   },
   async getMultipleChoiceDistractor({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'multiplechoicedistractor/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_MULTIPLECHOICEDISTRACTOR', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postMultipleChoiceDistractor({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'multiplechoicedistractor/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_MULTIPLECHOICEDISTRACTOR', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putMultipleChoiceDistractor({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'multiplechoicedistractor/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_MULTIPLECHOICEDISTRACTOR', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteMultipleChoiceDistractor({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'multiplechoicedistractor/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_MULTIPLECHOICEDISTRACTOR', item);
            fkRels.multipleChoiceDistractor.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getQuestionAnswerList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_QUESTIONANSWER_LOADING', true)
       const endpoint = 'questionanswer/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_QUESTIONANSWER', data);
            commit('SET_QUESTIONANSWER_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_QUESTIONANSWER_LOADING', false);
            reject(error)
       })
       })
   },
   async getQuestionAnswer({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'questionanswer/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_QUESTIONANSWER', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postQuestionAnswer({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'questionanswer/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_QUESTIONANSWER', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putQuestionAnswer({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'questionanswer/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_QUESTIONANSWER', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteQuestionAnswer({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'questionanswer/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_QUESTIONANSWER', item);
            fkRels.questionAnswer.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getFigureQuestionList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_FIGUREQUESTION_LOADING', true)
       const endpoint = 'figurequestion/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_FIGUREQUESTION', data);
            commit('SET_FIGUREQUESTION_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_FIGUREQUESTION_LOADING', false);
            reject(error)
       })
       })
   },
   async getFigureQuestion({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'figurequestion/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_FIGUREQUESTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postFigureQuestion({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'figurequestion/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_FIGUREQUESTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putFigureQuestion({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'figurequestion/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_FIGUREQUESTION', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteFigureQuestion({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'figurequestion/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_FIGUREQUESTION', item);
            fkRels.figureQuestion.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getFeedbackList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_FEEDBACK_LOADING', true)
       const endpoint = 'feedback/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_FEEDBACK', data);
            commit('SET_FEEDBACK_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_FEEDBACK_LOADING', false);
            reject(error)
       })
       })
   },
   async getFeedback({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'feedback/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_FEEDBACK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postFeedback({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'feedback/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_FEEDBACK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putFeedback({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'feedback/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_FEEDBACK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteFeedback({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'feedback/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_FEEDBACK', item);
            fkRels.feedback.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getFeedbackTypeList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_FEEDBACKTYPE_LOADING', true)
       const endpoint = 'feedbacktype/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_FEEDBACKTYPE', data);
            commit('SET_FEEDBACKTYPE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_FEEDBACKTYPE_LOADING', false);
            reject(error)
       })
       })
   },
   async getFeedbackType({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'feedbacktype/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_FEEDBACKTYPE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postFeedbackType({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'feedbacktype/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_FEEDBACKTYPE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putFeedbackType({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'feedbacktype/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_FEEDBACKTYPE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteFeedbackType({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'feedbacktype/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_FEEDBACKTYPE', item);
            fkRels.feedbackType.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getQuestionAnswerFeedbackList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_QUESTIONANSWERFEEDBACK_LOADING', true)
       const endpoint = 'questionanswerfeedback/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_QUESTIONANSWERFEEDBACK', data);
            commit('SET_QUESTIONANSWERFEEDBACK_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_QUESTIONANSWERFEEDBACK_LOADING', false);
            reject(error)
       })
       })
   },
   async getQuestionAnswerFeedback({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'questionanswerfeedback/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_QUESTIONANSWERFEEDBACK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postQuestionAnswerFeedback({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'questionanswerfeedback/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_QUESTIONANSWERFEEDBACK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putQuestionAnswerFeedback({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'questionanswerfeedback/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_QUESTIONANSWERFEEDBACK', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteQuestionAnswerFeedback({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'questionanswerfeedback/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_QUESTIONANSWERFEEDBACK', item);
            fkRels.questionAnswerFeedback.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getLearningStageList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_LEARNINGSTAGE_LOADING', true)
       const endpoint = 'learningstage/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_LEARNINGSTAGE', data);
            commit('SET_LEARNINGSTAGE_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_LEARNINGSTAGE_LOADING', false);
            reject(error)
       })
       })
   },
   async getLearningStage({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'learningstage/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_LEARNINGSTAGE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postLearningStage({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'learningstage/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_LEARNINGSTAGE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putLearningStage({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'learningstage/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_LEARNINGSTAGE', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteLearningStage({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'learningstage/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_LEARNINGSTAGE', item);
            fkRels.learningStage.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getScoringResultList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_SCORINGRESULT_LOADING', true)
       const endpoint = 'scoringresult/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_SCORINGRESULT', data);
            commit('SET_SCORINGRESULT_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_SCORINGRESULT_LOADING', false);
            reject(error)
       })
       })
   },
   async getScoringResult({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'scoringresult/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_SCORINGRESULT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postScoringResult({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'scoringresult/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_SCORINGRESULT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putScoringResult({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'scoringresult/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_SCORINGRESULT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteScoringResult({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'scoringresult/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_SCORINGRESULT', item);
            fkRels.scoringResult.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async getAchievementList({commit, state}, params=null){ 
     return new Promise((resolve, reject) => {
       commit('SET_ACHIEVEMENT_LOADING', true)
       const endpoint = 'achievement/';
       this.$axios.$get(endpoint, { params: params }).then((data) => {
            commit('SET_ACHIEVEMENT', data);
            commit('SET_ACHIEVEMENT_LOADING', false);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            commit('SET_ACHIEVEMENT_LOADING', false);
            reject(error)
       })
       })
   },
   async getAchievement({commit, state}, id){ 
     return new Promise((resolve, reject) => {
      const endpoint = 'achievement/' + id + '/';
       this.$axios.$get(endpoint).then((data) => {
            commit('UPDATE_ACHIEVEMENT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async postAchievement({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'achievement/';
       this.$axios.$post(endpoint, item).then((data) => {
            commit('INSERT_ACHIEVEMENT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async putAchievement({commit, state}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'achievement/' + item.id + '/';
       this.$axios.$put(endpoint, item).then((data) => {
            commit('UPDATE_ACHIEVEMENT', data);
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   async deleteAchievement({commit, state, dispatch}, item){ 
     return new Promise((resolve, reject) => {
       const endpoint = 'achievement/' + item.id + '/';
       this.$axios.$delete(endpoint, item).then((data) => {
            commit('DELETE_ACHIEVEMENT', item);
            fkRels.achievement.map(rel => dispatch('recursiveDelete', {modelName: rel.modelName, fieldName: rel.fieldName, id: item.id}))
            resolve(data)
       }).catch((error) => {
            commit('INSERT_ERROR', error);
            reject(error)
       })
       })
   },
   
   async loadAll({commit, dispatch}){
       dispatch('getRessourceList');
       dispatch('getLabelList');
       dispatch('getDeckList');
       dispatch('getLearningModeList');
       dispatch('getFeedbackList');
       dispatch('getFeedbackTypeList');
       dispatch('getLearningStageList');
   },
   async dispatchRequirements({dispatch, state}, requirements) {
       for (var req of requirements){
           if (state[req.charAt(0).toLowerCase() + req.slice(1)].length===0){
           dispatch('get' + req + 'List');
           }
       }
   },
   
           async getSchema({commit}) {
               let endpoint= 'schema/';
               await this.$axios.$get(endpoint).then((data) => { commit('SET_SCHEMA', data); });
           },
           async recursiveDelete({commit, state, dispatch}, item){ 
   let toDelIDS=JSON.parse(JSON.stringify(state[item.modelName].filter(fkitem=> fkitem[item.fieldName]===item.id).map(d=>d.id)));
   if (toDelIDS.length==0) return
   for (var did of toDelIDS) {
   commit('DELETE_' + item.modelName.toUpperCase(), did);
   }
   for (var fkRel of fkRels[item.modelName]) {
    for (var delID of toDelIDS){
     dispatch('recursiveDelete', {modelName: fkRel.modelName, fieldName: fkRel.fieldName, id: delID});
    }
   }},
   }