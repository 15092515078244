 
export const state = () => ({
    snackbars: []
  })
    
  export const mutations = {
    SET_SNACKBAR(state, snackbar) {
      state.snackbars = state.snackbars.concat(snackbar);
    },
    disableSnackbar(state, index){
        state.snackbars[index].showing=false;
    }
  }
    
  export const actions = {
    setSnackbar({commit, state}, snackbar) {
      snackbar.showing = true;
      snackbar.color = snackbar.color || 'success';
      commit('SET_SNACKBAR', snackbar);
      setTimeout(commit, 3000, 'disableSnackbar', state.snackbars.length-1)
    },
  }